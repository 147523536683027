import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import packageJson from '../package.json'
import axiosRetry from 'axios-retry'
import { getItineraries } from './repositories/itineraries'
import { getSpecials } from './repositories/specials'
import { getRaceWithSubrallies } from './repositories/race'
import { useLocation } from 'react-router-dom'
import { URLRoutes } from './types/urlRoutes'
import { Paths } from './utils/paths'
import { RaceMode } from './utils/raceMode'

axiosRetry(axios, {
    retries: Infinity, // number of retries
    retryDelay: retryCount => {
        return retryCount * 10000 // time interval between retries
    },
})

const MainUI = React.lazy(() => import('./MainUI').then(({ MainUI }) => ({ default: MainUI })))
const ErrorPage = React.lazy(() =>
    import('./pages/Error/ErrorPage').then(module => ({
        default: module.ErrorPage,
    }))
)

function isVersion(pathName: string) {
    return pathName.includes('version')
}

/* The main function of the app. */
function App() {
    const [subRallyId, setSubRallyId] = useState<number | null>(null)
    const [subRallyIdTracking, setSubRallyIdTracking] = useState<number | null>(null)
    const [itineraries, setItineraries] = useState<Itinerary[]>([])
    const [raceMode, setRaceMode] = useState<RaceMode>(RaceMode.OTHER)
    const [currentItinerary, setCurrentItinerary] = useState<Itinerary>()
    const [race, setRace] = useState<Race>()
    const [raceName, setRaceName] = useState<string>('')
    const [specials, setSpecials] = useState<Special[]>([])
    const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null)
    const [urlRoutes, setUrlRoutes] = useState<URLRoutes>({
        path: Paths.HOME,
        specialId: -1,
    })
    const location = useLocation()

    useEffect(() => {
        const checkURLChanges = (url: string) => {
            if (url === '/') {
                let urlRoutes: URLRoutes = {
                    path: Paths.HOME,
                    specialId: -1,
                }
                setUrlRoutes(urlRoutes)
            }

            var primaryTablesMatch = url.match(/\/(ss|par|gen)\/(\d+)\//)
            var otherTablesMatch = url.match(/\/(penalizations|entry|retired)/)

            if (primaryTablesMatch) {
                let type = primaryTablesMatch[1] as Paths
                let specialId = -1
                if (primaryTablesMatch[2]) {
                    specialId = Number(primaryTablesMatch[2])
                }
                let urlRoutes: URLRoutes = {
                    path: type,
                    specialId: specialId,
                }

                setUrlRoutes(urlRoutes)
            } else if (otherTablesMatch) {
                let type = otherTablesMatch[1] as Paths
                let urlRoutes: URLRoutes = {
                    path: type,
                    specialId: -1,
                }
                setUrlRoutes(urlRoutes)
            }
        }
        checkURLChanges(location.pathname)
    }, [location])

    const queryParameters = new URLSearchParams(window.location.search)
    let rallyId: number = -1

    const rallyIdString = queryParameters.get('rallyId')
    const itineraryIdString = queryParameters.get('itineraryId')

    var loadedItineraryId: number = -1
    var tempIsItineraryIdFromQuery: boolean = false

    if (itineraryIdString) {
        try {
            loadedItineraryId = parseInt(itineraryIdString)
            tempIsItineraryIdFromQuery = true
        } catch (error) {
            // alert(`Please insert a valid ItineraryId`)
            console.log('Error with ItineraryId')
        }
    }

    const [itineraryId, setItineraryId] = useState<number>(loadedItineraryId)
    const [isItineraryIdFromQuery, setIsItineraryIdFromQuery] = useState<boolean>(tempIsItineraryIdFromQuery)

    if (rallyIdString !== null) {
        rallyId = parseInt(rallyIdString)
    }

    if (errorMessage === null && rallyId === -1) {
        if (isVersion(window.location.pathname)) {
            setErrorMessage(<p>Version: {packageJson.version}</p>)
        } else {
            setErrorMessage(
                <p>
                    No rally has been specified. Please specify a valid URL parameter value for <b>rallyId</b>.
                </p>
            )
        }
    }

    /* A function that is called every 5 seconds to update the data from the backend. */
    const updateResources = useCallback(async () => {
        if (rallyId === -1) {
            return
        }

        var errorGettingData = false
        var tempItineraries: Itinerary[] = []
        var tempItineraryId: number = -1

        /* Get itineraries from API*/
        await getItineraries(rallyId).then(data => {
            if (!data || data.length <= 0) {
                setErrorMessage(<p>There was an error fetching itinerary data from the backend server.</p>)
                errorGettingData = true
                return
            }

            // Check if the query itineraryId is wrong
            if (isItineraryIdFromQuery) {
                var tempCurrentItinerary: Itinerary | undefined = data.find(
                    (itinerary: Itinerary) => itinerary.id === itineraryId
                )
                setCurrentItinerary(tempCurrentItinerary)

                if (!tempCurrentItinerary) {
                    setErrorMessage(
                        <p>
                            Please insert a valid <b>itinerary id</b> for that <b>race id</b>.
                        </p>
                    )
                    errorGettingData = true
                    return
                }
            }

            tempItineraries = data
            setItineraries(data)

            // Check if we read the itinerary id from query params
            if (isItineraryIdFromQuery) {
                tempItineraryId = itineraryId
            } else {
                setItineraryId(tempItineraries[0].id)
                tempItineraryId = tempItineraries[0].id
                setIsItineraryIdFromQuery(false)
                setCurrentItinerary(tempItineraries[0])
            }
        })

        if (errorGettingData) {
            return
        }

        /* Get specials from API */
        getSpecials(rallyId, tempItineraryId).then((data: Special[]) => {
            if (data.length <= 0) {
                setErrorMessage(<div>There was an error fetching specials data from the backend server.</div>)
                errorGettingData = true
                return
            }
            setSpecials(data)
        })

        if (errorGettingData) {
            return
        }

        /* Get race from API  */
        getRaceWithSubrallies(rallyId).then((data: Race) => {
            if (!data) {
                setErrorMessage(<p>No data yet for the race</p>)
                return
            }

            setRace(data)
            if (!data.active) {
                setErrorMessage(<>The rally is inactive</>)
            }
            setSubRallyId(data.subrally_id)
            setSubRallyIdTracking(data.subrally_id3)
            setRaceMode(data.mode !== 10 ? RaceMode.OTHER : RaceMode.CIRCUIT)
        })
    }, [rallyId, itineraryId])

    useEffect(() => {
        if (race && currentItinerary) {
            if (isItineraryIdFromQuery) {
                if (currentItinerary) {
                    setRaceName(currentItinerary.name)
                }
            } else {
                setRaceName(race.name)
            }
        }
    }, [isItineraryIdFromQuery, currentItinerary, race])

    useEffect(() => {
        updateResources()

        const interval = setInterval(() => {
            updateResources()
        }, 10000)

        return () => clearInterval(interval)
    }, [updateResources])

    if (errorMessage !== null) {
        return (
            <React.Suspense fallback={<></>}>
                <ErrorPage errorPage={errorMessage} />
            </React.Suspense>
        )
    }

    // FIXME: quick hack to avoid error if race is not ready
    if (!race) {
        return <div></div>
    }

    return (
        <React.Suspense fallback={<></>}>
            <MainUI
                raceMode={raceMode}
                rallyId={rallyId}
                specials={specials}
                itineraryId={itineraryId}
                raceName={raceName}
                race={race}
                subRallyId={subRallyId}
                subRallyIdTracking={subRallyIdTracking}
                urlRoutes={urlRoutes}
            />
        </React.Suspense>
    )
}

export default App
